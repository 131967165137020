import { connect } from "react-redux";
import React, { Component } from "react";
import { Button, CardContent, CardHeader, Grid, Header, Image, LabelDetail, Modal } from "semantic-ui-react";
import { formatQuery } from "../controllers/utilities/string-utils";
import KaisakuUserApi from "../controllers/kaisaku-user-api";
import cookie from "react-cookies";
import Lambda from "../controllers/utilities/aws-lambda";
import KaisakuApi, { getFullPath } from "../controllers/kaisaku-api";
import { navigate } from "gatsby";
import NavigationBar from "../components/forms/navigation-bar";
import btnq from "../images/task/btn_q.png";
import btnx from "../images/task/btn_x.png";
import btnok from "../images/task/ok.png";
import btnok2 from "../images/task/ok2.jpg";
import btnok21 from "../images/task/ok22.jpg";
import btn1 from "../images/task/btn1.png";
import btn2 from "../images/task/btn2.png";

// import caption from "../images/task/caption.jpg";
// import captionBox from "../images/task/box.png";
import caption from "../images/task/caption_2205.jpg";
import captionBox from "../images/task/box_2205.png";

// import caption1 from "../images/task/caption1.jpg"; // caption1  // 上 80次
import caption2 from "../images/task/caption22.png";
import caption3 from "../images/task/caption33.jpg";
import caption33 from "../images/task/caption33_2205.jpg";
import b0 from "../images/task/black/b_0.png";
import b1 from "../images/task/black/b_1.png";
import b2 from "../images/task/black/b_2.png";
import b3 from "../images/task/black/b_3.png";
import b4 from "../images/task/black/b_4.png";
import b5 from "../images/task/black/b_5.png";
import b6 from "../images/task/black/b_6.png";
import b7 from "../images/task/black/b_7.png";
import b8 from "../images/task/black/b_8.png";
import b9 from "../images/task/black/b_9.png";
import bb from "../images/task/black/bb.png";
import bbb from "../images/task/black/bbb.png";
import bbbb from "../images/task/black/bbbb.png";

import r0 from "../images/task/red/r_0.png";
import r1 from "../images/task/red/r_1.png";
import r2 from "../images/task/red/r_2.png";
import r3 from "../images/task/red/r_3.png";
import r4 from "../images/task/red/r_4.png";
import r5 from "../images/task/red/r_5.png";
import r6 from "../images/task/red/r_6.png";
import r7 from "../images/task/red/r_7.png";
import r8 from "../images/task/red/r_8.png";
import r9 from "../images/task/red/r_9.png";
import r10 from "../images/task/red/r_10.png";
import { getUA } from "react-device-detect";
import AndroidComm from "../controllers/utilities/unity-android-communication";
import Helmet from "react-helmet";
import { showFJB } from "./sdk-ad-noodle-entr";

import tgb0 from "../images/task/subitem/0.png";
import tgb1 from "../images/task/subitem/1.png";
import tgb2 from "../images/task/subitem/2.png";
import tgb3 from "../images/task/subitem/3.png";
import tgb4 from "../images/task/subitem/4.png";
import tgb5 from "../images/task/subitem/5.png";
import tgb6 from "../images/task/subitem/6.png";
import tgb7 from "../images/task/subitem/7.png";
import tgb8 from "../images/task/subitem/8.png";
import tgb9 from "../images/task/subitem/9.png";
import tgbt from "../images/task/subitem/t.png";
import tgbp from "../images/task/subitem/p.png";
import tgbbb from "../images/task/subitem/n.png";

import g1_1 from "../images/task/obj/g20.png";
import g1_2 from "../images/task/obj/g20_get.gif";
import g1_3 from "../images/task/obj/g20_saleout.png";
import g1_4 from "../images/task/obj/g20_give.png";

import g3_1 from "../images/task/obj/g20.png";
import g3_2 from "../images/task/obj/g20_get.gif";
import g3_3 from "../images/task/obj/g20_saleout.png";
import g3_4 from "../images/task/obj/g20_give.png";

import g6_1 from "../images/task/obj/g20.png";
import g6_2 from "../images/task/obj/g20_get.gif";
import g6_3 from "../images/task/obj/g20_saleout.png";
import g6_4 from "../images/task/obj/g20_give.png";

import g10_1 from "../images/task/obj/g20.png";
import g10_2 from "../images/task/obj/g20_get.gif";
import g10_3 from "../images/task/obj/g20_saleout.png";
import g10_4 from "../images/task/obj/g20_give.png";

import g20_1 from "../images/task/obj/g20.png";
import g20_2 from "../images/task/obj/g20_get.gif";
import g20_3 from "../images/task/obj/g20_saleout.png";
import g20_4 from "../images/task/obj/g20_give.png";

import g30_1 from "../images/task/obj/g20.png";
import g30_2 from "../images/task/obj/g20_get.gif";
import g30_3 from "../images/task/obj/g20_saleout.png";
import g30_4 from "../images/task/obj/g20_give.png";

import g50_1 from "../images/task/obj/g20.png";
import g50_2 from "../images/task/obj/g20_get.gif";
import g50_3 from "../images/task/obj/g20_saleout.png";
import g50_4 from "../images/task/obj/g20_give.png";

import g80_1 from "../images/task/obj/g30.png";
import g80_2 from "../images/task/obj/g30_get.gif";
import g80_3 from "../images/task/obj/g30_saleout.png";
import g80_4 from "../images/task/obj/g30_give.png";

export default connect(state => {
    return {
        session: state.session,
        ...state.sdkSettings,
    };
})(class extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            newbtnok2: btnok2,
            url: "",
            index: 1,
            startTime: 0,
            endTime: 0,
            transactionId: '',
            userId: '',
            gameId: '',
            eventId: '',
            accessToken: '',
            nickname: '',
            createdAt: '',
            expireAt: '',
            expireAt_TTL: '',
            divStyle: '',

            showInfo: false,
            monthCount: 0,
            allcount: 0,

            ready: false,
            open: false,
            week: {},
            weekshow1: "",
            taskCount: 0,
            pos: 7, // 上80次 两页是7
            re: re,
            cooltime: -1,
        };
        let { showInfo, monthCount, allcount, url, index, startTime, endTime, transactionId, eventId, userId, gameId, accessToken, nickname, createdAt, expireAt, expireAt_TTL, re } = formatQuery(this.props.location.search);
        if (parseInt(allcount) === 0) {
            allcount = 1;
        }
        this.state.showInfo = showInfo;
        console.log("showInfo =" + showInfo);
        this.state.monthCount = monthCount;
        this.state.allcount = allcount;
        this.state.url = url;

        this.state.index = index;
        console.log("index =" + index);
        this.state.startTime = startTime;
        this.state.endTime = endTime;
        this.state.transactionId = transactionId;

        this.state.userId = userId;
        this.state.gameId = gameId;
        this.state.accessToken = accessToken;
        this.state.nickname = nickname;
        this.state.createdAt = createdAt;
        this.state.expireAt = expireAt;
        this.state.expireAt_TTL = expireAt_TTL;
        if (eventId === undefined) eventId = "";
        this.state.eventId = eventId;
        this.state.re = re;

        // 1 帐号管理过去
        // 2 支付云闪付过去
        // 3 安卓广告过去 
        // 4 苹果广告过去
        // 5 支付结果过去
        // 6 登录 无广告 过去
        if (this.state.re === "3" || this.state.re === "4" || this.state.re === "6") {
            this.state.cooltime = 11;
            console.log(this.state.cooltime);
            this.state.expireTimer = setInterval(async () => {
                let { cooltime } = this.state;
                console.log(--cooltime);
                if ((cooltime <= 9) && (cooltime >= 1)) {
                    this.setState({ cooltime, newbtnok2: btnok21 });
                }
                this.setState({ cooltime });
                if (cooltime === 0) {
                    clearInterval(this.state.expireTimer);
                    console.log("自动进入下一步");
                    this.onReturn();
                    this.state.cooltime = 0;
                    this.setState({ cooltime: 0, newbtnok2: btnok2 });
                }
            }, 1000);
        }

    }

    componentWillUnmount() {
        // window.removeEventListener('resize', this.getDirection);        
    }

    getDirection = () => {
        this.setState({ state: this.state });
    }

    async getTaskStatus() {
        if (!isValidSession(this.props) || (this.state.accessToken)) {
            let accessToken = this.state.accessToken;
            if (!accessToken) {
                accessToken = cookie.load("_accessToken");
            }
            console.log("到这");
            return await KaisakuApi.getTaskStatus2(accessToken);
        } else {
            console.log("到那");
            return await new KaisakuUserApi(this.props.session).getTaskStatus();
        }
    }

    async componentDidMount() {
        let data = await this.getTaskStatus();

        if (data.status === 200) {
            const weekshow1 = dateFormat(data.data.week.startDate, "dd/MM/yyyy") + "-" + dateFormat(data.data.week.endDate, "dd/MM/yyyy");
            console.log(weekshow1);
            let taskCount = data.data.taskCount;
            // if (taskCount >= 10) taskCount = 10;
            if (taskCount >= 100) taskCount = 99;
            this.setState({
                ready: true,
                week: data.data.week,
                weekshow1: weekshow1,
                // taskCount: data.data.taskCount
                taskCount: taskCount,
                data: data.data
            });
        } else {
            this.setState({
                ready: true,
            });
        }
    }

    async onReceive(objId, taskCount) {
        const b1 = getObjstatus(objId, this.state.data);
        if (b1 === false) return;
        if (this.state.taskCount < taskCount) return;
        if (getObjCount(objId, this.state.data) === 0) return;
        if (this.state.expireTimer) {
            clearInterval(this.state.expireTimer);
            this.state.expireTimer = undefined;
            console.log("取消定时器");
        }
        navigate(`/unionpay-page2${this.props.location.search}&objId=${objId}`);

    }

    onClose() {
        this.setState({
            open: false
        });
    }

    onOpen() {
        this.setState({
            open: true
        });
    }

    async onReturn() {
        if (this.state.expireTimer) {
            clearInterval(this.state.expireTimer);
            this.state.expireTimer = undefined;
            console.log("取消定时器");
        }
        if (this.state.re === "1") {
            // console.log("帐号管理");
            navigate(`/sdk-callback`);
        } else if (this.state.re === "2") {
            // console.log("购买页");
            navigate(`/confirm-purchase`);
        } else if (this.state.re === "3") {
            try {
                console.log({ gameId: this.state.gameId, userId: this.state.userId, accessToken: this.state.accessToken, nickname: this.state.nickname, createdAt: this.state.createdAt, expireAt: this.state.expireAt });
                const res = await KaisakuApi.getNoodleInfo(this.state.gameId, this.state.accessToken);
                if ((res.data.success) || ((res.data.loginData) && (res.data.loginData.showInfo))) {
                    if (res.data.success) {
                        if (res.data.cardtype) {
                            res.data.index = res.data.cardtype;
                        }
                        navigate(`/sdk-ad-noodle-entr/?showInfo=${res.data.loginData.showInfo}&monthCount=${res.data.loginData.monthCount}&allcount=${res.data.loginData.allcount}&url=${res.data.url}&index=${res.data.index}&startTime=${res.data.startTime}&endTime=${res.data.endTime}&attributionId=${res.data.attributionId}&transactionId=${res.data.transactionId}&eventId=${this.state.eventId}&gameId=${this.state.gameId}&accessToken=${this.state.accessToken}&userId=${this.state.userId}&nickname=${this.state.nickname}&createdAt=${this.state.createdAt}&expireAt=${this.state.expireAt}`);
                    } else {
                        navigate(`/sdk-ad-noodle-entronly/?showInfo=${res.data.loginData.showInfo}&monthCount=${res.data.loginData.monthCount}&allcount=${res.data.loginData.allcount}&startTime=${res.data.startTime}&endTime=${res.data.endTime}&attributionId=${res.data.attributionId}&transactionId=${res.data.transactionId}&eventId=${this.state.eventId}&gameId=${this.state.gameId}&accessToken=${this.state.accessToken}&userId=${this.state.userId}&nickname=${this.state.nickname}&createdAt=${this.state.createdAt}&expireAt=${this.state.expireAt}`);
                    }
                    // if (res.data.url){
                    //     if (res.data.cardtype){
                    //         res.data.index = res.data.cardtype;
                    //     }
                    //     navigate(`/${res.data.url}/?index=${res.data.index}&startTime=${res.data.startTime}&endTime=${res.data.endTime}&attributionId=${res.data.attributionId}&transactionId=${res.data.transactionId}&eventId=${this.state.eventId}&gameId=${this.state.gameId}&accessToken=${this.state.accessToken}&userId=${this.state.userId}&nickname=${this.state.nickname}&createdAt=${this.state.createdAt}&expireAt=${this.state.expireAt}`);
                    // }else{
                    //     navigate(`/sdk-ad-noodle/?startTime=${res.data.startTime}&endTime=${res.data.endTime}&attributionId=${res.data.attributionId}&transactionId=${res.data.transactionId}&eventId=${this.state.eventId}&gameId=${this.state.gameId}&accessToken=${this.state.accessToken}&userId=${this.state.userId}&nickname=${this.state.nickname}&createdAt=${this.state.createdAt}&expireAt=${this.state.expireAt}`);
                    // }
                } else {
                    let issdkorapp = false;
                    issdkorapp = getUA.includes("JGGSDKWebView");
                    let platform = cookie.load("_platform");
                    if ((issdkorapp) || (platform === "h5")) {
                        // this.closeAd();
                        // await showFJB(this.state.accessToken, this.state.gameId, this.state.userId, this.state.nickname, this.state.createdAt, this.state.expireAt, this.state.expireAt_TTL);
                        // 去掉飞机杯，加回来
                        AndroidComm.sendSession({ gameId: this.state.gameId, userId: this.state.userId, accessToken: this.state.accessToken, nickname: this.state.nickname, createdAt: this.state.createdAt, expireAt: this.state.expireAt, expireAt_TTL: this.state.expireAt_TTL });
                    } else {
                        // this.closeAd();
                        this.setState({
                            divStyle: {
                                width: '289px',
                                borderRadius: '10px 10px 0px 0px',
                                display: 'none'
                            }
                        });
                    }
                }
            } catch (err) {
                // await showFJB(this.state.accessToken, this.state.gameId, this.state.userId, this.state.nickname, this.state.createdAt, this.state.expireAt, this.state.expireAt_TTL);
                AndroidComm.sendSession({ gameId: this.state.gameId, userId: this.state.userId, accessToken: this.state.accessToken, nickname: this.state.nickname, createdAt: this.state.createdAt, expireAt: this.state.expireAt, expireAt_TTL: this.state.expireAt_TTL });
            }

        } else if (this.state.re === "4") {
            try {
                const res = await KaisakuApi.getNoodleInfo(this.state.gameId, this.state.accessToken);
                if ((res.data.success) || ((res.data.loginData) && (res.data.loginData.showInfo))) {
                    if (res.data.success) {
                        if (res.data.cardtype) {
                            res.data.index = res.data.cardtype;
                        }
                        navigate(`/sdk-ad-noodle-entr/?showInfo=${res.data.loginData.showInfo}&monthCount=${res.data.loginData.monthCount}&allcount=${res.data.loginData.allcount}&url=${res.data.url}&index=${res.data.index}&startTime=${res.data.startTime}&endTime=${res.data.endTime}&attributionId=${res.data.attributionId}&transactionId=${res.data.transactionId}&eventId=${this.state.eventId}&gameId=${this.state.gameId}&accessToken=${this.state.accessToken}&userId=${this.state.userId}&nickname=${this.state.nickname}&createdAt=${this.state.createdAt}&expireAt=${this.state.expireAt}`);
                    } else {
                        navigate(`/sdk-ad-noodle-entronly/?showInfo=${res.data.loginData.showInfo}&monthCount=${res.data.loginData.monthCount}&allcount=${res.data.loginData.allcount}&startTime=${res.data.startTime}&endTime=${res.data.endTime}&attributionId=${res.data.attributionId}&transactionId=${res.data.transactionId}&eventId=${this.state.eventId}&gameId=${this.state.gameId}&accessToken=${this.state.accessToken}&userId=${this.state.userId}&nickname=${this.state.nickname}&createdAt=${this.state.createdAt}&expireAt=${this.state.expireAt}`);
                    }
                    // if (res.data.url){
                    //     if (res.data.cardtype){
                    //         res.data.index = res.data.cardtype;
                    //     }
                    //     navigate(`/${res.data.url}/?index=${res.data.index}&startTime=${res.data.startTime}&endTime=${res.data.endTime}&attributionId=${res.data.attributionId}&transactionId=${res.data.transactionId}&eventId=${this.state.eventId}&gameId=${this.state.gameId}&accessToken=${this.state.accessToken}&userId=${this.state.userId}&nickname=${this.state.nickname}&createdAt=${this.state.createdAt}&expireAt=${this.state.expireAt}`);
                    // }else{
                    //     navigate(`/sdk-ad-noodle/?startTime=${res.data.startTime}&endTime=${res.data.endTime}&attributionId=${res.data.attributionId}&transactionId=${res.data.transactionId}&eventId=${this.state.eventId}&gameId=${this.state.gameId}&accessToken=${this.state.accessToken}&userId=${this.state.userId}&nickname=${this.state.nickname}&createdAt=${this.state.createdAt}&expireAt=${this.state.expireAt}`);
                    // }
                } else {
                    // this.closeAd();
                    // console.log({ gameId:this.state.gameId, userId: this.state.userId, accessToken: this.state.accessToken,nickname: this.state.nickname,createdAt: this.state.createdAt, expireAt: this.state.expireAt });
                    // await showFJB(this.state.accessToken, this.state.gameId, this.state.userId, this.state.nickname, this.state.createdAt, this.state.expireAt, this.state.expireAt_TTL);
                    // 去掉飞机杯，加回来
                    AndroidComm.sendSession({ gameId: this.state.gameId, userId: this.state.userId, accessToken: this.state.accessToken, nickname: this.state.nickname, createdAt: this.state.createdAt, expireAt: this.state.expireAt, expireAt_TTL: this.state.expireAt_TTL });
                }
            } catch (err) {
                // await showFJB(this.state.accessToken, this.state.gameId, this.state.userId, this.state.nickname, this.state.createdAt, this.state.expireAt, this.state.expireAt_TTL);
                AndroidComm.sendSession({ gameId: this.state.gameId, userId: this.state.userId, accessToken: this.state.accessToken, nickname: this.state.nickname, createdAt: this.state.createdAt, expireAt: this.state.expireAt, expireAt_TTL: this.state.expireAt_TTL });
            }
        } else if (this.state.re === "5") {
            navigate("/credit-purchase-ok-result/" + this.props.location.search);
        } else if (this.state.re === "6") {
            // console.log("返回游戏");
            // await showFJB(this.state.accessToken, this.state.gameId, this.state.userId, this.state.nickname, this.state.createdAt, this.state.expireAt, this.state.expireAt_TTL);
            // 去掉飞机杯，加回来
            AndroidComm.sendSession({ gameId: this.state.gameId, userId: this.state.userId, accessToken: this.state.accessToken, nickname: this.state.nickname, createdAt: this.state.createdAt, expireAt: this.state.expireAt, expireAt_TTL: this.state.expireAt_TTL });
        }

    }

    render() {
        const mode = detectOrient();
        return (
            <>
                <Helmet>
                    <style>{'body:before { background-image:none; background-color: #282828; }'}</style>
                </Helmet>
                {(<div className="whiteDiv" textAlign='center' style={{
                    width: "100%",
                    minHeight: "100vh",
                    textAlign: "center",
                    margin: "0 auto",

                }} >
                    <div style={{ Width: "100%", height: "100vh", margin: "0 auto" }}>
                        <div style={{ paddingTop: "4rem" }}>

                            <Grid textAlign='center' style={{
                                margin: '0',
                                width: '100%',
                                backgroundColor: "transparent",
                            }} verticalAlign='top'>


                                <Grid.Column style={{ maxWidth: 450 }}>
                                    <div style={{ width: "100%" }} >
                                        <div>
                                            <Image style={{
                                                width: "100%",
                                            }} centered
                                                src={caption}
                                                onClick={this.onReturn.bind(this)}
                                            />
                                            {(this.state.ready) && (<div style={{ position: "absolute", top: "3%", right: "4%", width: "10%" }}><Image style={{
                                                width: "100%",
                                            }} centered
                                                onClick={this.onOpen.bind(this)}
                                                src={btnq}
                                            /></div>)}
                                            {(this.state.ready) && (<div style={{ position: "absolute", top: `${this.state.taskCount >= 10 ? "21%" : "20.5%"}`, right: `${this.state.taskCount >= 10 ? "8%" : "10.5%"}`, width: `${this.state.taskCount >= 10 ? "16%" : "24%"}` }}>

                                                {(this.state.taskCount >= 10) && (
                                                    <div style={{ float: "left", width: "50%" }}>
                                                        <div style={{ position: "relative", top: "0", left: "20%" }}><Image style={{
                                                            width: "100%",
                                                        }} centered
                                                            src={returnRImage(this.state.taskCount, 2)}
                                                        /></div>
                                                    </div>)}
                                                <div style={{ float: "right", width: "50%" }}>
                                                    <div style={{ position: "relative", top: "0", right: `${this.state.taskCount >= 10 ? "20%" : "0%"}` }}>
                                                        <Image style={{
                                                            width: "100%",
                                                        }} centered
                                                            src={returnRImage(this.state.taskCount, 1)}
                                                        /></div>
                                                </div>

                                            </div>)}
                                            {(this.state.weekshow1 !== "") && (<div style={{ position: "absolute", top: "27%", left: "8%", height: "2.6%" }}>
                                                <div style={{ float: "left", height: "100%" }}><Image style={{
                                                    height: "100%",
                                                }} centered
                                                    src={returnBImage(this.state.weekshow1[0])}
                                                /></div>
                                                <div style={{ float: "left", height: "100%" }}><Image style={{
                                                    height: "100%",
                                                }} centered
                                                    src={returnBImage(this.state.weekshow1[1])}
                                                /></div>
                                                <div style={{ float: "left", height: "100%" }}><Image style={{
                                                    height: "100%",
                                                }} centered
                                                    src={returnBImage(this.state.weekshow1[2])}
                                                /></div>
                                                <div style={{ float: "left", height: "100%" }}><Image style={{
                                                    height: "100%",
                                                }} centered
                                                    src={returnBImage(this.state.weekshow1[3])}
                                                /></div>
                                                <div style={{ float: "left", height: "100%" }}><Image style={{
                                                    height: "100%",
                                                }} centered
                                                    src={returnBImage(this.state.weekshow1[4])}
                                                /></div>
                                                <div style={{ float: "left", height: "100%" }}><Image style={{
                                                    height: "100%",
                                                }} centered
                                                    src={returnBImage(this.state.weekshow1[5])}
                                                /></div>
                                                <div style={{ float: "left", height: "100%" }}><Image style={{
                                                    height: "100%",
                                                }} centered
                                                    src={returnBImage(this.state.weekshow1[6])}
                                                /></div>
                                                <div style={{ float: "left", height: "100%" }}><Image style={{
                                                    height: "100%",
                                                }} centered
                                                    src={returnBImage(this.state.weekshow1[7])}
                                                /></div>
                                                <div style={{ float: "left", height: "100%" }}><Image style={{
                                                    height: "100%",
                                                }} centered
                                                    src={returnBImage(this.state.weekshow1[8])}
                                                /></div>
                                                <div style={{ float: "left", height: "100%" }}><Image style={{
                                                    height: "100%",
                                                }} centered
                                                    src={returnBImage(this.state.weekshow1[9])}
                                                /></div>
                                                <div style={{ float: "left", height: "100%" }}><Image style={{
                                                    height: "100%",
                                                }} centered
                                                    src={returnBImage(this.state.weekshow1[10])}
                                                /></div>
                                                <div style={{ float: "left", height: "100%" }}><Image style={{
                                                    height: "100%",
                                                }} centered
                                                    src={returnBImage(this.state.weekshow1[11])}
                                                /></div>
                                                <div style={{ float: "left", height: "100%" }}><Image style={{
                                                    height: "100%",
                                                }} centered
                                                    src={returnBImage(this.state.weekshow1[12])}
                                                /></div>
                                                <div style={{ float: "left", height: "100%" }}><Image style={{
                                                    height: "100%",
                                                }} centered
                                                    src={returnBImage(this.state.weekshow1[13])}
                                                /></div>
                                                <div style={{ float: "left", height: "100%" }}><Image style={{
                                                    height: "100%",
                                                }} centered
                                                    src={returnBImage(this.state.weekshow1[14])}
                                                /></div>
                                                <div style={{ float: "left", height: "100%" }}><Image style={{
                                                    height: "100%",
                                                }} centered
                                                    src={returnBImage(this.state.weekshow1[15])}
                                                /></div>
                                                <div style={{ float: "left", height: "100%" }}><Image style={{
                                                    height: "100%",
                                                }} centered
                                                    src={returnBImage(this.state.weekshow1[16])}
                                                /></div>
                                                <div style={{ float: "left", height: "100%" }}><Image style={{
                                                    height: "100%",
                                                }} centered
                                                    src={returnBImage(this.state.weekshow1[17])}
                                                /></div>
                                                <div style={{ float: "left", height: "100%" }}><Image style={{
                                                    height: "100%",
                                                }} centered
                                                    src={returnBImage(this.state.weekshow1[18])}
                                                /></div>
                                                <div style={{ float: "left", height: "100%" }}><Image style={{
                                                    height: "100%",
                                                }} centered
                                                    src={returnBImage(this.state.weekshow1[19])}
                                                /></div>
                                                <div style={{ float: "left", height: "100%" }}><Image style={{
                                                    height: "100%",
                                                }} centered
                                                    src={returnBImage(this.state.weekshow1[20])}
                                                /></div>
                                            </div>)}

                                            <div style={{ position: "absolute", top: "31%", width: "93%", height: "58%", overflowY: "auto" }}>
                                                <Image style={{
                                                    width: "100%",
                                                }} centered
                                                    src={captionBox}
                                                />

                                                {(findObj("g1", this.state.data) >= 0) && (this.state.ready) && (
                                                    <div onClick={() => {
                                                        this.onReceive("g1", 1);
                                                    }}>
                                                        <div style={{ position: "absolute", top: "1%", right: "6%", height: "13%" }}>
                                                            <Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={getObj1("g1", this.state.data, this.state.taskCount)}
                                                            />
                                                        </div>

                                                        {(this.state.ready) && (<div style={{ position: "absolute", top: "1%", right: "5%", height: "3%" }}>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage("t")}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage((getObjCount("g1", this.state.data)).toString()[0])}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage((getObjCount("g1", this.state.data)).toString()[1])}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage((getObjCount("g1", this.state.data)).toString()[2])}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage((getObjCount("g1", this.state.data)).toString()[3])}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage((getObjCount("g1", this.state.data)).toString()[4])}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage((getObjCount("g1", this.state.data)).toString()[5])}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage("p")}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%", width: "0.5rem" }}></div>
                                                        </div>)}

                                                    </div>
                                                )}

                                                {(findObj("g3", this.state.data) >= 0) && (this.state.ready) && (
                                                    <div onClick={() => {
                                                        this.onReceive("g3", 3);
                                                    }}>
                                                        <div style={{ position: "absolute", top: "14%", right: "6%", height: "13%" }}>
                                                            <Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={getObj3("g3", this.state.data, this.state.taskCount)}
                                                            />
                                                        </div>

                                                        {(this.state.ready) && (<div style={{ position: "absolute", top: "14%", right: "5%", height: "3%" }}>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage("t")}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage((getObjCount("g3", this.state.data)).toString()[0])}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage((getObjCount("g3", this.state.data)).toString()[1])}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage((getObjCount("g3", this.state.data)).toString()[2])}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage((getObjCount("g3", this.state.data)).toString()[3])}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage((getObjCount("g3", this.state.data)).toString()[4])}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage((getObjCount("g3", this.state.data)).toString()[5])}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage("p")}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%", width: "0.5rem" }}></div>
                                                        </div>)}

                                                    </div>
                                                )}

                                                {(findObj("g6", this.state.data) >= 0) && (this.state.ready) && (
                                                    <div onClick={() => {
                                                        this.onReceive("g6", 6);
                                                    }}>
                                                        <div style={{ position: "absolute", top: "28%", right: "6%", height: "13%" }}>
                                                            <Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={getObj6("g6", this.state.data, this.state.taskCount)}
                                                            />
                                                        </div>

                                                        {(this.state.ready) && (<div style={{ position: "absolute", top: "28%", right: "5%", height: "3%" }}>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage("t")}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage((getObjCount("g6", this.state.data)).toString()[0])}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage((getObjCount("g6", this.state.data)).toString()[1])}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage((getObjCount("g6", this.state.data)).toString()[2])}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage((getObjCount("g6", this.state.data)).toString()[3])}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage((getObjCount("g6", this.state.data)).toString()[4])}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage((getObjCount("g6", this.state.data)).toString()[5])}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage("p")}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%", width: "0.5rem" }}></div>
                                                        </div>)}

                                                    </div>
                                                )}

                                                {(findObj("g10", this.state.data) >= 0) && (this.state.ready) && (
                                                    <div onClick={() => {
                                                        this.onReceive("g10", 10);
                                                    }}>
                                                        <div style={{ position: "absolute", top: "42%", right: "6%", height: "13%" }}>
                                                            <Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={getObj10("g10", this.state.data, this.state.taskCount)}
                                                            />
                                                        </div>

                                                        {(this.state.ready) && (<div style={{ position: "absolute", top: "42%", right: "5%", height: "3%" }}>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage("t")}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage((getObjCount("g10", this.state.data)).toString()[0])}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage((getObjCount("g10", this.state.data)).toString()[1])}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage((getObjCount("g10", this.state.data)).toString()[2])}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage((getObjCount("g10", this.state.data)).toString()[3])}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage((getObjCount("g10", this.state.data)).toString()[4])}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage((getObjCount("g10", this.state.data)).toString()[5])}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage("p")}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%", width: "0.5rem" }}></div>
                                                        </div>)}

                                                    </div>
                                                )}

                                                {(findObj("g20", this.state.data) >= 0) && (this.state.ready) && (
                                                    <div onClick={() => {
                                                        this.onReceive("g20", 20);
                                                    }}>
                                                        <div style={{ position: "absolute", top: "57%", right: "6%", height: "13%" }}>
                                                            <Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={getObj20("g20", this.state.data, this.state.taskCount)}
                                                            />
                                                        </div>

                                                        {(this.state.ready) && (<div style={{ position: "absolute", top: "57%", right: "5%", height: "3%" }}>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage("t")}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage((getObjCount("g20", this.state.data)).toString()[0])}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage((getObjCount("g20", this.state.data)).toString()[1])}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage((getObjCount("g20", this.state.data)).toString()[2])}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage((getObjCount("g20", this.state.data)).toString()[3])}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage((getObjCount("g20", this.state.data)).toString()[4])}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage((getObjCount("g20", this.state.data)).toString()[5])}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage("p")}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%", width: "0.5rem" }}></div>
                                                        </div>)}

                                                    </div>
                                                )}

                                                {(findObj("g30", this.state.data) >= 0) && (this.state.ready) && (
                                                    <div onClick={() => {
                                                        this.onReceive("g30", 30);
                                                    }}>
                                                        <div style={{ position: "absolute", top: "71%", right: "6%", height: "13%" }}>
                                                            <Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={getObj30("g30", this.state.data, this.state.taskCount)}
                                                            />
                                                        </div>

                                                        {(this.state.ready) && (<div style={{ position: "absolute", top: "71%", right: "5%", height: "3%" }}>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage("t")}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage((getObjCount("g30", this.state.data)).toString()[0])}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage((getObjCount("g30", this.state.data)).toString()[1])}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage((getObjCount("g30", this.state.data)).toString()[2])}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage((getObjCount("g30", this.state.data)).toString()[3])}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage((getObjCount("g30", this.state.data)).toString()[4])}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage((getObjCount("g30", this.state.data)).toString()[5])}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage("p")}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%", width: "0.5rem" }}></div>
                                                        </div>)}

                                                    </div>
                                                )}

                                                {(findObj("g50", this.state.data) >= 0) && (this.state.ready) && (
                                                    <div onClick={() => {
                                                        this.onReceive("g50", 50);
                                                    }}>
                                                        <div style={{ position: "absolute", top: "85.5%", right: "6%", height: "13%" }}>
                                                            <Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={getObj50("g50", this.state.data, this.state.taskCount)}
                                                            />
                                                        </div>

                                                        {(this.state.ready) && (<div style={{ position: "absolute", top: "85.5%", right: "5%", height: "3%" }}>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage("t")}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage((getObjCount("g50", this.state.data)).toString()[0])}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage((getObjCount("g50", this.state.data)).toString()[1])}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage((getObjCount("g50", this.state.data)).toString()[2])}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage((getObjCount("g50", this.state.data)).toString()[3])}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage((getObjCount("g50", this.state.data)).toString()[4])}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage((getObjCount("g50", this.state.data)).toString()[5])}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage("p")}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%", width: "0.5rem" }}></div>
                                                        </div>)}

                                                    </div>
                                                )}


                                                {(findObj("g80", this.state.data) >= 0) && (this.state.ready) && (
                                                    <div onClick={() => {
                                                        this.onReceive("g80", 80);
                                                    }}>
                                                        <div style={{ position: "absolute", top: "99.5%", right: "6%", height: "13%" }}>
                                                            <Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={getObj80("g80", this.state.data, this.state.taskCount)}
                                                            />
                                                        </div>

                                                        {(this.state.ready) && (<div style={{ position: "absolute", top: "99.5%", right: "5%", height: "3%" }}>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage("t")}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage((getObjCount("g80", this.state.data)).toString()[0])}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage((getObjCount("g80", this.state.data)).toString()[1])}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage((getObjCount("g80", this.state.data)).toString()[2])}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage((getObjCount("g80", this.state.data)).toString()[3])}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage((getObjCount("g80", this.state.data)).toString()[4])}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage((getObjCount("g80", this.state.data)).toString()[5])}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                                height: "100%",
                                                            }} centered
                                                                src={returnItemImage("p")}
                                                            /></div>
                                                            <div style={{ float: "left", height: "100%", width: "0.5rem" }}></div>
                                                        </div>)}
                                                    </div>
                                                )}

                                                {(this.state.taskCount >= 1) && (<div style={{ position: "absolute", top: "0%", left: "12%", width: "15%" }}><Image style={{
                                                    width: "100%",
                                                }} centered
                                                    src={btnok}
                                                /></div>)}

                                                {(this.state.taskCount >= 3) && (<div style={{ position: "absolute", top: "13.5%", left: "12%", width: "15%" }}><Image style={{
                                                    width: "100%",
                                                }} centered
                                                    src={btnok}
                                                /></div>)}

                                                {(this.state.taskCount >= 6) && (<div style={{ position: "absolute", top: "27.5%", left: "12%", width: "15%" }}><Image style={{
                                                    width: "100%",
                                                }} centered
                                                    src={btnok}
                                                /></div>)}

                                                {(this.state.taskCount >= 10) && (<div style={{ position: "absolute", top: "41.5%", left: "12%", width: "15%" }}><Image style={{
                                                    width: "100%",
                                                }} centered
                                                    src={btnok}
                                                /></div>)}

                                                {(this.state.taskCount >= 20) && (<div style={{ position: "absolute", top: "56%", left: "12%", width: "15%" }}><Image style={{
                                                    width: "100%",
                                                }} centered
                                                    src={btnok}
                                                /></div>)}

                                                {(this.state.taskCount >= 30) && (<div style={{ position: "absolute", top: "70.5%", left: "12%", width: "15%" }}><Image style={{
                                                    width: "100%",
                                                }} centered
                                                    src={btnok}
                                                /></div>)}

                                                {(this.state.taskCount >= 50) && (<div style={{ position: "absolute", top: "85%", left: "12%", width: "15%" }}><Image style={{
                                                    width: "100%",
                                                }} centered
                                                    src={btnok}
                                                /></div>)}

                                                {(this.state.taskCount >= 80) && (<div style={{ position: "absolute", top: "98.5%", left: "12%", width: "15%" }}><Image style={{
                                                    width: "100%",
                                                }} centered
                                                    src={btnok}
                                                /></div>)}

                                            </div>

                                        </div>
                                        <div style={{ width: "100%" }}><Image style={{
                                            width: "100%",
                                        }} centered
                                            onClick={this.onReturn.bind(this)}
                                            src={this.state.newbtnok2}
                                        />
                                            {(this.state.cooltime > 0) && (this.state.cooltime <= 9) && (this.state.cooltime >= 1) && (<div
                                                style={{ position: "absolute", height: "2.5em", top: "91%", right: "16%", width: "9%" }}>
                                                <Image style={{
                                                    height: "100%",
                                                }} centered
                                                    src={returnRImage(this.state.cooltime, 1)}
                                                />
                                            </div>)}
                                        </div>
                                    </div>
                                    <Modal
                                        style={{ width: '96%', maxWidth: 450 }}
                                        open={this.state.open}
                                    >
                                        <Image style={{
                                            width: "100%",
                                        }} centered
                                            src={(findObj("g50", this.state.data) >= 0 ? (findObj("g10", this.state.data) >= 0 ? caption33 : caption3) : caption2)}
                                        />
                                        <div style={{ position: "absolute", top: "3%", right: "3%", width: "8%" }}><Image style={{
                                            width: "100%",
                                        }} centered
                                            onClick={this.onClose.bind(this)}
                                            src={btnx}
                                        /></div>

                                        <div style={{ position: "absolute", bottom: "3%", left: "20%", width: "60%" }}><Image style={{
                                            width: "100%",
                                        }} centered
                                            src={btn2}
                                            onClick={() => {
                                                navigate(`/sdk-teaching3/${this.props.location.search}&t3re=3`);
                                            }}
                                        /></div>
                                    </Modal>
                                </Grid.Column>


                            </Grid>
                        </div></div>
                </div>)}
            </>
        );
    }


});

function dateFormat(time, fmt) { // author: meizz
    const date = new Date(time);
    // console.log(date);
    let o = {
        "M+": date.getMonth() + 1, // 月份
        "d+": date.getDate(), // 日
        "h+": date.getHours(), // 小时
        "m+": date.getMinutes(), // 分
        "s+": date.getSeconds(), // 秒
        "q+": Math.floor((date.getMonth() + 3) / 3), // 季度
        "S": date.getMilliseconds() // 毫秒
    };
    if (/(y+)/.test(fmt))
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (let k in o)
        if (new RegExp("(" + k + ")").test(fmt))
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}

function detectOrient() {
    if (typeof window !== `undefined`) {
        // let isIOS = "0";
        // if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {  //判断iPhone|iPad|iPod|iOS
        //     // 暂时去掉ios两板功能
        //     isIOS = "1";
        // }
        // if (isIOS === "0") return "PORTRAIT";
        if (window.innerHeight >= window.innerWidth) {
            // 竖屏
            // console.log("竖屏");
            return "PORTRAIT";
        } else {
            // 横屏
            // console.log("横屏");
            return "LANDSCAPE";
        }
    } else {
        return "";
    }
}

function findObj(oId, data) {
    console.log(oId);
    console.log(data);
    if (!data) return -1;
    const data1 = data.ActivityInfo;
    for (let j = 0; j < data1.length; j++) {
        const item = data1[j];
        if (item.objId === oId) {
            return (item.maxCount - item.saleCount)
        }
    }
    return -1;
}

function getObj1(oId, data, taskCount) {
    if (getObjstatus(oId, data)) {
        if (getObjCount("g1", data) === 0) {
            return g20_3;
        } else if (taskCount >= 1) {
            return g20_2;
        } else {
            return g20_1;
        }
    } else {
        return g20_4;
    }
}

function getObj3(oId, data, taskCount) {
    if (getObjstatus(oId, data)) {
        if (getObjCount("g3", data) === 0) {
            return g20_3;
        } else if (taskCount >= 3) {
            return g20_2;
        } else {
            return g20_1;
        }
    } else {
        return g20_4;
    }
}

function getObj6(oId, data, taskCount) {
    if (getObjstatus(oId, data)) {
        if (getObjCount("g6", data) === 0) {
            return g20_3;
        } else if (taskCount >= 6) {
            return g20_2;
        } else {
            return g20_1;
        }
    } else {
        return g20_4;
    }
}

function getObj10(oId, data, taskCount) {
    if (getObjstatus(oId, data)) {
        if (getObjCount("g10", data) === 0) {
            return g20_3;
        } else if (taskCount >= 10) {
            return g20_2;
        } else {
            return g20_1;
        }
    } else {
        return g20_4;
    }
}

function getObj20(oId, data, taskCount) {
    if (getObjstatus(oId, data)) {
        if (getObjCount("g20", data) === 0) {
            return g20_3;
        } else if (taskCount >= 20) {
            return g20_2;
        } else {
            return g20_1;
        }
    } else {
        return g20_4;
    }
}

function getObj30(oId, data, taskCount) {
    if (getObjstatus(oId, data)) {
        if (getObjCount("g30", data) === 0) {
            return g30_3;
        } else if (taskCount >= 30) {
            return g30_2;
        } else {
            return g30_1;
        }
    } else {
        return g30_4;

    }
}

function getObj50(oId, data, taskCount) {
    if (getObjstatus(oId, data)) {
        if (getObjCount("g50", data) === 0) {
            return g50_3;
        } else if (taskCount >= 50) {
            return g50_2;
        } else {
            return g50_1;
        }
    } else {
        return g50_4;
    }
}

function getObj80(oId, data, taskCount) {
    if (getObjstatus(oId, data)) {
        if (getObjCount("g80", data) === 0) {
            return g80_3;
        } else if (taskCount >= 80) {
            return g80_2;
        } else {
            return g80_1;
        }
    } else {
        return g80_4;

    }
}

function getObjstatus(oId, data) {
    const data1 = data.userActivityInfo;
    console.log("到这");
    console.log(data1);
    if (data1) {
        for (let j = 0; j < data1.length; j++) {
            const item = data1[j];
            if (item.objId === oId) {
                console.log("到这");
                return false;
            }
        }
    }
    return true;
}

function getObjCount(oId, data) {
    if (!data) return 0;
    const data1 = data.ActivityInfo;
    for (let j = 0; j < data1.length; j++) {
        const item = data1[j];
        if (item.objId === oId) {
            return (item.maxCount - item.saleCount)
        }
    }
    return 0;
}

function returnBImage(code) {

    switch (code) {
        case "1":
            return b1;
        case "2":
            return b2;
        case "3":
            return b3;
        case "4":
            return b4;
        case "5":
            return b5;
        case "6":
            return b6;
        case "7":
            return b7;
        case "8":
            return b8;
        case "9":
            return b9;
        case "/":
            return bb;
        case "-":
            return bbb;
        case "~":
            return bbbb;
        default:
            return b0;
    }
}

function returnRImage(code, no) {
    let code1 = "0" + code + "";
    if (code >= 10) {
        code1 = code + "";
    }
    if (no === 1) code1 = code1[1];
    if (no === 2) code1 = code1[0];
    console.log(code1);
    switch (code1) {
        case "0":
            return r0;
        case "1":
            return r1;
        case "2":
            return r2;
        case "3":
            return r3;
        case "4":
            return r4;
        case "5":
            return r5;
        case "6":
            return r6;
        case "7":
            return r7;
        case "8":
            return r8;
        case "9":
            return r9;
        // case 10:
        //     return r10;
        default:
            return r0;
    }
}

function returnItemImage(code) {
    switch (code) {
        case "0":
            return tgb0;
        case "1":
            return tgb1;
        case "2":
            return tgb2;
        case "3":
            return tgb3;
        case "4":
            return tgb4;
        case "5":
            return tgb5;
        case "6":
            return tgb6;
        case "7":
            return tgb7;
        case "8":
            return tgb8;
        case "9":
            return tgb9;
        case "t":
            return tgbt;
        case "p":
            return tgbp;
    }
}

function isValidSession(props) {
    if (!props) return false;
    return !!props.session && !!props.session.accessToken;
}